import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { UserService } from '@core/user/user.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ProfileResponse, UserData } from '@core/user/user.types';
import { HttpNormalResponseData } from '@shared/interfaces/http-response-interface';

@Injectable({ providedIn: 'root' })
export class AuthService {
	// readonly isAuthenticated = signal<boolean>(false);
	private _authenticated: boolean = false;
	private _isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _hasGeneratedEmail: boolean = false;
	private _serverUrl: string = environment.serverUrl;

	constructor(
		private _httpClient: HttpClient,
		private _userService: UserService,
		private _cookieService: SsrCookieService
	) {}

	// -----------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------

	/**
	 * Setter & getter for access token
	 */
	set accessToken(token: string) {
		if (this._cookieService.check('accessToken')) {
			this._cookieService.delete('accessToken', '/');
		}

		this._cookieService.set('accessToken', token, undefined, '/');
	}

	/**
	 * getter for access token
	 */
	get accessToken(): string {
		return this._cookieService.get('accessToken') ?? '';
	}

	/**
	 * getter auth
	 */
	get isAuthenticated$(): Observable<boolean> {
		return this._isAuthenticated.asObservable();
	}

	/**
	 * getter generate email
	 */
	get hasGeneratedEmail(): boolean {
		return this._hasGeneratedEmail;
	}

	// -----------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------

	/**
	 * Sign in
	 *
	 * @param credentials
	 */
	signIn(credentials: any): Observable<any> {
		// Throw error, if the user is already logged in
		if (this._authenticated) {
			// return throwError('User is already logged in.');
			return throwError(() => new Error('User is already logged in.'));
		}

		const url = `${this._serverUrl}/auth/login`;

		return this._httpClient.post<any>(url, credentials).pipe(
			switchMap((resp: any) => {
				const res = resp as HttpNormalResponseData<ProfileResponse>;

				if (res.ok) {
					// Store the access token in the local storage
					this.accessToken = res.data.token;

					// Set the authenticated flag to true
					// this.isAuthenticated.set(true);
					this._authenticated = true;
					this._isAuthenticated.next(true);

					// Set the generated email flag to true
					this._hasGeneratedEmail = res.data.user.generated_email === 1;

					// Store user active plan
					this._userService.active_plan = res.data.active_plan;

					// Store the user on the user service
					this._userService.user = res.data.user;
				}
				return of(resp);
			})
		);
	}

	/**
	 * Sign in using the access token
	 */
	signInUsingToken(): Observable<boolean> {
		const url = `${this._serverUrl}/user_data`;

		return this._httpClient.get(url).pipe(
			catchError(() => {
				// Clear token
				this.clearToken();

				// Return false
				return of(false);
			}),
			switchMap((resp: any) => {
				if (resp.ok) {
					const res = resp as HttpNormalResponseData<UserData>;

					// Replace the access token with the new one if it's available on
					if (res.token) {
						this.accessToken = res.token;
					}

					// Set the authenticated flag to true
					// this.isAuthenticated.set(true);
					this._authenticated = true;
					this._isAuthenticated.next(true);

					// Set the generated email flag to true
					this._hasGeneratedEmail = res.data.generated_email === 1;

					// Store user active plan
					this._userService.active_plan = res.data.active_plan;

					// Store the user on the user service
					this._userService.user = res.data;

					// Return true
					return of(true);
				} else {
					//Clear toke
					this.clearToken();
					// Return true
					return of(false);
				}
			})
		);
	}

	/**
	 * Sign out
	 */
	signOut(): Observable<any> {
		// Set the authenticated flag to false
		// this.isAuthenticated.set(false);
		this._authenticated = false;
		this._isAuthenticated.next(false);

		//Set endpoint
		const url = `${this._serverUrl}/auth/logout`;

		return this._httpClient.post<any>(url, {}).pipe(
			switchMap((resp: any) => {
				this.clearToken();

				return of(resp);
			})
		);
	}

	/**
	 * Sign up as seller ocasional
	 *
	 * @param user
	 */
	signUp(user: any): Observable<ProfileResponse> {
		const url = `${this._serverUrl}/auth/signup`;

		return this._httpClient.post<ProfileResponse>(url, user).pipe(
			switchMap((res: ProfileResponse) => {
				if (res.token) {
					// Store the access token in the local storage
					this.accessToken = res.token;

					// Set the authenticated flag to true
					// this.isAuthenticated.set(true);
					this._authenticated = true;
					this._isAuthenticated.next(true);

					// Set the generated email flag to true
					this._hasGeneratedEmail = res.user.generated_email === 1;

					// Store the user on the user service
					this._userService.user = res.user;

					return of(res);
				} else {
					return throwError(() => new Error('error'));
				}
			})
		);
	}

	/**
	 * Sign up as seller profesional
	 *
	 * @param user
	 */
	signUpProf(user: any): Observable<ProfileResponse> {
		const url = `${this._serverUrl}/auth/professional_seller`;

		return this._httpClient.post<ProfileResponse>(url, user).pipe(
			switchMap((res: ProfileResponse) => {
				if (res.token) {
					// Store the access token in the local storage
					this.accessToken = res.token;

					// Set the authenticated flag to true
					// this.isAuthenticated.set(true);
					this._authenticated = true;
					this._isAuthenticated.next(true);

					// Set the generated email flag to true
					this._hasGeneratedEmail = res.user.generated_email === 1;

					// Store the user on the user service
					this._userService.user = res.user;

					return of(res);
				} else {
					return throwError(() => new Error('error'));
				}
			})
		);
	}

	/**
	 * Check the authentication status
	 */
	check(): Observable<boolean> {
		// Check if the user is logged in
		if (this._authenticated) {
			return of(true);
		}

		// Check the access token availability
		if (!this.accessToken) {
			return of(false);
		}

		// If the access token exists, and it didn't expire, sign in using it
		return this.signInUsingToken();
	}

	clearToken(): void {
		// Retrieve cookies as an array-like object
		const cookies = this._cookieService.getAll() as any;
		this._cookieService.delete('accessToken', '/');

		// Use a regular loop to iterate (safer for non-array objects)
		for (const cookieName in cookies) {
			if (cookieName.startsWith('accessToken')) {
				this._cookieService.delete(cookieName, '/');
			}
		}
	}
}
